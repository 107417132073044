/* Import Fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Montserrat:wght@300;400;500&display=swap');

/* Root Variables */
:root {
  /* Background Gradient Colors (softer versions of loading colors) */
  --bg-color-1: #fae8e1; /* Soft orange */
  --bg-color-2: #fce4ec; /* Soft pink */
  --bg-color-3: #e1f5fe; /* Soft blue */
  --bg-color-4: #e0f2f1; /* Soft teal */

  /* Main Color Scheme */
  --primary-color: #ffffff; /* White background */
  --secondary-color: #f5f7fa; /* Very light blue-gray for sections */
  --accent-color: #e6a8a8; /* Softer dusty rose accent */
  --text-color: #2c3e50; /* Dark blue-gray for text */
  --text-color-alt: #5d6d7e; /* Lighter blue-gray for alternate text */

  /* Button Colors */
  --button-background: var(--accent-color);
  --button-text-color: #ffffff;
  --button-hover-background: #d99393; /* Slightly darker dusty rose */
  --button-active: #cc7e7e; /* Even darker dusty rose for active state */
  --button-hover-alt: #c7e6fd; /* Light blue for alternate hover */
  --button-active-lines: #bf6969; /* Darker shade for active lines or borders */

  /* Additional Accent Colors */
  --accent-light-orange: #f5d0c3; /* Light orange accent */
  --accent-light-pink: #f5c7d7; /* Light pink accent */
  --accent-light-blue: #c7e6fd; /* Light blue accent */
  --accent-light-teal: #c4e8e2; /* Light teal accent */

  /* Loading Colors */
  --loading-color-1: #ee7752; /* Orange */
  --loading-color-2: #e73c7e; /* Pink */
  --loading-color-3: #23a6d5; /* Blue */
  --loading-color-4: #23d5ab; /* Teal */

  /* New Modern Variables */
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
  --border-radius: 8px;
}

/* Base Styles */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: var(--text-color);
  line-height: 1.6;
}

body {
  background: linear-gradient(-60deg, var(--bg-color-1), var(--bg-color-2), var(--bg-color-3), var(--bg-color-4));
  background-size: 400% 400%;
  animation: subtleGradientAnimation 8s ease-in-out infinite;
  background-attachment: fixed;
  color: var(--text-color);
}

/* Headings */
h1,
h2,
h3 {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  color: var(--text-color);
  text-align: center;
  margin: 0;
  letter-spacing: -0.25px;
}

h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

h2 {
  font-size: 2em;
  margin-bottom: 16px;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 12px;
}

/* Paragraphs */
p {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Buttons */
button {
  padding: 12px 24px;
  background-color: var(--button-background);
  color: var(--button-text-color);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
}

button:hover {
  background-color: var(--button-hover-background);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:disabled:hover {
  background-color: #ccc;
  transform: none;
}

button.selected {
  background-color: var(--button-active);
}

button.submit {
  align-items: center;
  width: 323px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--button-active);
  font-size: 1.1em;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button.submit:hover {
  color: var(--button-active-lines);
}

button.submit:disabled {
  background-color: #ccc;
}

/* Content Container */
.content-container {
  position: relative;
  min-height: 100vh;
  overflow-y: auto;
  z-index: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Home Page Container */
.homePageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 20px 60px;
  color: #61685f;
  position: relative;
}

.homePageContainer::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(10px);
  z-index: -1;
  animation: pulseBlur 5s infinite alternate;
}

.homePageContainer > * {
  position: relative;
  z-index: 2;
}

.homePageContainer .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  padding: 15px;
  border-radius: 15px;
}

.homePageContainer .title h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.homePageContainer .title h4 {
  font-size: 1.2em;
  font-weight: 300;
  margin: 0;
}

.homePageContainer .logo {
  width: 80px; /* Adjust the width as needed */
  height: auto;
  margin-bottom: -10px; /* Adjust to align with text */
}

.homePageContainer .startbutton,
.homePageContainer .bottomstartbutton {
  padding: 15px 30px;
  border-radius: 50px;
  background-color: var(--button-background);
  color: var(--button-text-color);
  font-size: 1.1em;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 5px;
}

.homePageContainer .startbutton:hover,
.homePageContainer .bottomstartbutton:hover {
  background-color: var(--button-hover-background);
  transform: translateY(-2px);
}

.homePageContainer a {
  text-align: center;
  padding-bottom: 10px;
}

.homePageContainer .homepageText {
  max-width: 800px;
  padding: 20px;
}

.homePageContainer .homepageText p {
  font-size: 1em;
  line-height: 1.6;
  color: var(--text-color);
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  padding: 15px;
  border-radius: 15px;
}

/* Scroll Container */
.scroll-container {
  overflow-x: auto;
  overflow-y: visible;
  scroll-behavior: smooth;
}

.scroll-items {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 15px 0 10px;
  overflow-y: visible;
}

.scroll-items::-webkit-scrollbar {
  display: none;
}

.scroll-item {
  flex: 0 0 auto;
  width: 270px;
  margin-right: 10px;
  padding: 15px;
  border: 1px solid #ccc;
  text-align: left;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
}

.scroll-item:last-child {
  margin-right: 0;
}

.scroll-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  position: relative; /* Create a new positioning context */
  z-index: 1; 
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.scroll-item h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 1.1em;
  color: #333;
}

.scroll-item p {
  margin: 10px 0;
  font-size: 0.85em;
  color: #555;
}

.scroll-item p.big {
  font-size: 1.1em;
}

.item-feature {
  font-size: 0.9em;
  color: #777;
  margin-top: 10px;
  font-weight: bold;
}

.item-description {
  font-size: 0.80em;
  color: #888;
  margin-bottom: 10px;
}

.additional-option {
  text-align: center;
  margin-top: 20px;
}

.additional-option a {
  color: #007BFF;
  text-decoration: none;
}

.additional-option a:hover {
  text-decoration: underline;
}

.selected-item {
  transform: translateY(-3px);
  border: 2px solid var(--accent-color);
}

/* End Scroll Container */

/* Layout */
.body-container > h2 {
  letter-spacing: -0.75px;
  margin: 0 2vw;
}

.footer-submit {
  width: 90dvw;
  margin: 0 5dvw;
}

.why-disabled {
  font-size: 0.6em;
  text-align: center;
  color: var(--accent-color);
}

.multiselect-button {
  margin: 10px; 
  width: 40dvw;
  height: 8dvh;
}

.big-text-area {
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  font-size: 1em;
  resize: vertical;
}

.summary-content {
  font-size: 0.8rem;
  padding: 0 15px;
  max-width: 600px;
  margin: 0 auto;
}

.intro-text, .outro-text {
  font-size: 0.95rem;
  margin-bottom: 15px;
}

.steps-list {
  padding-left: 20px;
}

.steps-list li {
  margin-bottom: 10px;
}

.multiselect-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(-45deg, 
    var(--loading-color-1), 
    var(--loading-color-2), 
    var(--loading-color-3), 
    var(--loading-color-4)
  );
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  padding: 0 20px; /* Add horizontal padding */
}

.loader {
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  max-width: 80%; /* Limit the width of the text */
}

.phase-description {
  max-width: 80%; /* Limit the width of the text */
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0 10px; /* Add some extra padding to the description */
}

@keyframes subtleGradientAnimation {
  0%, 100% {
    background-position: 0% 50%;
    opacity: 0.9;
  }
  25% {
    background-position: 70% 30%;
    opacity: 1;
  }
  50% {
    background-position: 100% 50%;
    opacity: 0.9;
  }
  75% {
    background-position: 30% 70%;
    opacity: 1;
  }
}

@keyframes pulseBlur {
  0% {
    filter: blur(10px);
  }
  100% {
    filter: blur(15px);
  }
}

.section {
  background-color: var(--secondary-color);
}

.accent-text {
  color: var(--accent-color);
}

button {
  background-color: var(--button-background);
  color: var(--button-text-color);
}

button:hover {
  background-color: var(--button-hover-background);
}

.alternate-button:hover {
  background-color: var(--button-hover-alt);
}

/* New Modern Styles */
.card {
  background-color: var(--primary-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
  margin-bottom: 20px;
  transition: var(--transition);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.input {
  width: 85vw;
  padding: 2vh 2vw;
  border: 1px solid var(--text-color-alt);
  border-radius: var(--border-radius);
  font-size: 1em;
  transition: var(--transition);
}

.input:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(230, 168, 168, 0.2);
}

.modern-link, .modern-link:visited, .modern-link:hover {
  text-decoration: none !important;
}

.modern-link {
  color: var(--accent-color);
  text-decoration: none;
  position: relative;
  transition: var(--transition);
  font-weight: 500;
  padding: 5px 10px;
  border-radius: var(--border-radius);
}

.modern-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--accent-color);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.modern-link:hover {
  background-color: rgba(230, 168, 168, 0.1);
  text-decoration: none;
}

.modern-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.dashboard {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.order-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.order-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;
}

.order-header {
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.recipe-info {
    margin-top: 10px;
}

.recipe-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.recipe-info {
    margin-bottom: 24px;
    padding: 16px;
}

.recipe-metadata {
    display: flex;
    justify-content: space-between;
    color: #666;
    font-size: 0.9em;
}

.phase-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    margin-top: 16px;
}

.phase-card {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 16px;
}

.phase-emoji {
    margin-right: 8px;
}

.ingredients-list {
    margin-top: 12px;
}

.ingredient-row {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    border-bottom: 1px solid #eee;
}

.ingredient-name {
    color: #333;
}

.ingredient-percentage {
    font-weight: 500;
    color: #666;
}

.primary-button {
    width: 100%;
    margin-top: 24px;
    padding: 12px;
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.1em;
    cursor: pointer;
    transition: background 0.2s;
}

.primary-button:hover {
    background: var(--primary-color-dark);
}

.variation-request-section {
  margin: 20px 0;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.variation-request-section h2 {
  margin-top: 0;
  color: #333;
  font-size: 1.2em;
}

.variation-request-section ul {
  list-style-type: none;
  padding-left: 0;
}

.variation-request-section li {
  margin: 5px 0;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
}

.variation-request-section p {
  margin: 10px 0;
}


.build-variation-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.variation-request-info {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.recipe-editor {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.total-percentage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  font-size: 1.1em;
  font-weight: bold;
}

.percentage-indicator {
  margin-left: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.percentage-indicator.valid {
  background-color: #4caf50;
  color: white;
}

.percentage-indicator.invalid {
  background-color: #f44336;
  color: white;
}

.ingredients-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.ingredient-input {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 6px;
}

.ingredient-input label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.percentage-input {
  display: flex;
  align-items: center;
}

.percentage-input input {
  width: 80px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 5px;
}

.percentage-symbol {
  color: #666;
}

.base-percentage {
  margin-top: 8px;
  font-size: 0.9em;
  color: #666;
}

.ingredient-typeahead {
  position: relative;
  width: 100%;
  margin: 20px 0;
}

.typeahead-input {
  width: 100%;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 6px;
  font-size: 1em;
  transition: border-color 0.2s;
}

.typeahead-input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  margin-top: 4px;
}

.suggestion-item {
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
}

.suggestion-item:last-child {
  border-bottom: none;
}

.suggestion-item:hover {
  background-color: #f5f5f5;
}

.suggestion-name {
  font-weight: 500;
  color: #333;
}

.suggestion-meta {
  display: flex;
  gap: 8px;
  margin-top: 4px;
  font-size: 0.9em;
}

.suggestion-type {
  color: var(--primary-color);
  font-weight: 500;
}

.suggestion-description {
  color: #666;
}

.no-suggestions {
  padding: 12px;
  text-align: center;
  color: #666;
  background: #f5f5f5;
  border-radius: 6px;
  margin-top: 4px;
}

.product-selector {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
}

.area-button,
.type-button {
  width: 100%;
  padding: 1.5rem;
  text-align: left;
  background: var(--accent-color);
  border: 2px solid #eee;
  border-radius: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.area-button:hover,
.type-button:hover {
  border-color: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.area-button h3,
.type-button h3 {
  margin: 0;
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: 600;
}

.area-button p,
.type-button p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
  line-height: 1.4;
}

.area-button.selected,
.type-button.selected {
  border-color: var(--primary-color);
  background-color: var(--primary-color-light);
}
